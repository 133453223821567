
import { api } from "@/api/api";
import { ApiGetMemberOrganizationDto } from "@/api/generated/Api";
import { AuthRole } from "@/auth/auth.constants";
import { authService } from "@/auth/authService";
import MemberOrganizationUserModal from "@/components/administration/member-organization-users/MemberOrganizationUserModal.vue";
import MemberOrganizationModal from "@/components/administration/member-organizations/MemberOrganizationModal.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { AdminRouteNames } from "@/shared/enums/RouteNames/adminRouteNamesEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useRouter } from "@/shared/useHelpers";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "MemberOrganizationPage",
  components: {
    MemberOrganizationModal,
    MemberOrganizationUserModal,
    BaseTableFiltered,
    BaseModal,
    BaseLayout,
  },

  setup() {
    const modalData = ref(getInitialModalData());
    const offices = ref<ApiGetMemberOrganizationDto[]>([]);
    const search = ref("");
    const router = useRouter();

    const headers = [
      { text: "Navn", value: "name" },
      { text: "Handlinger", value: "actions" },
      { text: "E-post", value: "email" },
      {
        text: "Aktiv",
        value: "isActive",
      },
    ];

    onMounted(() => {
      getAllOrganizations();
    });

    const canModifyMemberOrganization = authService.hasRoles(AuthRole.ModifyMemberOrganization);
    const canCreateMemberOrganization = authService.hasRoles(AuthRole.CreateMemberOrganization);

    const getAllOrganizations = async () => {
      await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        offices.value = (await api.organization.getMemberOrganizationsAsync()).data;
      });
      offices.value = offices.value.filter((office) => !office.parentId);
    };

    const openNewOrg = useOpenModal(ModalType.Add, "organisasjon", modalData);
    const openEditOrg = useOpenModal(ModalType.Edit, "organisasjon", modalData);
    const viewMemberOrgUsers = useOpenModal(ModalType.Display, "Legg til e-post - hovedkontor", modalData);

    const viewMemberOrg = async (item: ApiGetMemberOrganizationDto) => {
      router.push({
        name: AdminRouteNames.MainOffice,
        params: { mainOfficeId: item.id.toString() },
      });
    };

    return {
      ModalType,
      modalData,
      offices,
      openNewOrg,
      openEditOrg,
      headers,
      search,
      getAllOrganizations,
      viewMemberOrg,
      viewMemberOrgUsers,
      canModifyMemberOrganization,
      canCreateMemberOrganization,
    };
  },
});
